










































import _ from 'lodash';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Section4 extends Vue {
  columns = ((total: number, chunk: number) => {
    const ranges = _.range(total);
    const count = _.ceil(total / chunk);
    return _.chunk(ranges, count);
  })(30, 3);
}
