


























import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Section6 extends Vue {
  cards = [{
    name: 'Rita',
    message: '很棒的app，容易上手，終於不會半途而廢記帳這件事了(笑)',
  }, {
    name: 'Rita',
    message: '很棒的app，容易上手，終於不會半途而廢記帳這件事了(笑)',
  }, {
    name: 'Rita',
    message: '很棒的app，容易上手，終於不會半途而廢記帳這件事了(笑)',
  }, {
    name: 'Rita',
    message: '很棒的app，容易上手，終於不會半途而廢記帳這件事了(笑)',
  }, {
    name: 'Rita',
    message: '很棒的app，容易上手，終於不會半途而廢記帳這件事了(笑)',
  } ];
}
