import { render, staticRenderFns } from "./section-5.vue?vue&type=template&id=40241922&scoped=true&"
import script from "./section-5.ts?vue&type=script&lang=ts&"
export * from "./section-5.ts?vue&type=script&lang=ts&"
import style0 from "./section-5.sass?vue&type=style&index=0&id=40241922&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40241922",
  null
  
)

export default component.exports