








import 'swiper/dist/css/swiper.min.css';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Swiper, { SwiperOptions } from 'swiper';

@Component({
  mounted() {
    const self = this as SwiperComponent;
    if (!self.$el) { return; }

    self.swiper = new Swiper(
      self.$el as HTMLDivElement,
      self.options,
    );
  },
})
export default class SwiperComponent extends Vue {
  swiper!: Swiper;

  @Prop({ type: Object })
  options!: SwiperOptions;
}
