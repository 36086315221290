import { floor } from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SwiperOptions } from 'swiper';
import SwiperComponent from '@/components/Swiper/Swiper.vue';

@Component({
  components: {
    Swiper: SwiperComponent,
  },
})
export default class Section5 extends Vue {

  @Prop(String)
  title!: string;

  @Prop(String)
  paragraph!: string;

  @Prop(Array)
  pics!: any[];

  get swiperOptions(): SwiperOptions {
    return {
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 30,
      initialSlide: this.$isServer
        ? 0
        : window.innerWidth <= 576
          ? 0
          : floor(this.pics.length / 2),
    };
  }
}
