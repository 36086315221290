import { Component, Vue } from 'vue-property-decorator';
import Section1 from '@/views/Home/section-1.vue';
import Section2 from '@/views/Home/section-2.vue';
import Section3 from '@/views/Home/section-3.vue';
import Section4 from '@/views/Home/section-4.vue';
import Section5 from '@/views/Home/section-5.vue';
import Section6 from '@/views/Home/section-6.vue';

@Component({
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
  },
  head() {
    return {
      titleTemplate: '%s',
      title: this.$t('home.title').toString(),
    };
  },
})
export default class Home extends Vue {
  get sliders() {
    const lang = (() => {
      switch (this.$i18n.locale) {
        case 'cn':
          return 'tw';
        default:
          return this.$i18n.locale;
      }
    })();

    return [{
      // 截圖
      title: this.$t('home.section-5[0].title'),
      paragraph: this.$t('home.section-5[0].paragraph'),
      pics: [
        require(`@/assets/images/home/${lang}/snapshot-1.png`),
        require(`@/assets/images/home/${lang}/snapshot-2.png`),
        require(`@/assets/images/home/${lang}/snapshot-3.png`),
        require(`@/assets/images/home/${lang}/snapshot-4.png`),
        require(`@/assets/images/home/${lang}/snapshot-5.png`),
        require(`@/assets/images/home/${lang}/snapshot-6.png`),
        require(`@/assets/images/home/${lang}/snapshot-7.png`),
        require(`@/assets/images/home/${lang}/snapshot-8.png`),
        require(`@/assets/images/home/${lang}/snapshot-9.png`),
      ],
    }, {
      // widget
      title: this.$t('home.section-5[1].title'),
      paragraph: this.$t('home.section-5[1].paragraph'),
      pics: [
        require('@/assets/images/home/widget-1.png'),
        require('@/assets/images/home/widget-2.png'),
        require('@/assets/images/home/widget-3.png'),
        require('@/assets/images/home/widget-4.png'),
        require('@/assets/images/home/widget-5.png'),
        require('@/assets/images/home/widget-6.png'),
        require('@/assets/images/home/widget-7.png'),
      ],
    }, {
      // AppleWatch
      title: this.$t('home.section-5[2].title'),
      paragraph: this.$t('home.section-5[2].paragraph'),
      pics: [
        require('@/assets/images/home/watch-1.png'),
        require('@/assets/images/home/watch-2.png'),
        require('@/assets/images/home/watch-3.png'),
        require('@/assets/images/home/watch-4.png'),
        require('@/assets/images/home/watch-5.png'),
        require('@/assets/images/home/watch-6.png'),
        require('@/assets/images/home/watch-7.png'),
      ],
    }];
  }
}
